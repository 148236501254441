import React from "react";
import ArticleListing from "./Article";

function Programming(){
    return(
        <div>
            <br /><br />

            <section className="text-gray-600 body-font">
            <h1 className="flex justify-center items-center px-auto text-xl font-bold py-6 dark:text-sky-700">Programming</h1>
                <ArticleListing category={"programming"}></ArticleListing>
            </section>
        </div>
    )
}

export default  Programming;