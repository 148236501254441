import {React, useState, useEffect} from "react";
import HomeFooter from "./HomeFooter";
import TopAppBar from "./TopAppBar";
import engineer from "../assets/images/drone.jpg";
import style from "../assets/css/style.css";



function LazyBackgroundImageTech({img}) {
  const [loaded, setLoaded] = useState(false);
  const handleLoad = () => {
    setLoaded(true);
  };
return (
 <div className="h-3/4"
    style={{
      backgroundImage: `url("${img}")`,
      backgroundRepeat:"no-repeat", backgroundSize:"cover", backgroundPosition:"center",
      filter: loaded ? "none" : "blur(20px)",
      transition: "filter 0.5s",
    }}>
    <img src={img} alt="" onLoad={handleLoad} style={{ display: "none" }} />
    {loaded}

    <div className="md:container md:mx-auto justify-center flex justify-center items-center px-auto" >
      <div className="flex flex-col justify-center items-start row-start-2 sm:row-start-1 py-12 px-6">
        <br /><br /><br />
        <h1 className="text-3xl lg:text-4xl xl:text-5xl font-medium text-white leading-normal">
        <br />  <strong></strong>
        </h1><br /><br />
        <p className="text-xl text-white mt-4 mb-6">
          <br /> <br /><br />
        </p>  
        </div>
          <div className="flex w-full">
            <div className="h-full w-full" >
            </div>
          </div>
    </div>
 </div>
);
}

function TextHeader(){
  return(
    <header className="w-full container mx-auto">
          <br /><br />
   <div className="flex flex-col items-center py-6">

            <a className="font-bold text-red-400 uppercase hover:text-gray-700 text-3xl" href="/">
                High-tech leaks
            </a>
            <p className=" text-base text-black-500 dark:text-white text-sm text-base italic font-semibold">
                Latest tech <span className="text-purple-400">news</span>, 
                <span  className="text-gray-400"> gadgets</span> and 
                <span className="text-blue-400"> innovations</span>.
            </p>

    </div>
    </header>
  );
}


  
function ArticleCard ({id, title, category, date, monthyear, coverimage, shortdescription}){
  return (
      <>
      <div className="sm:w-1/2  mb-10 px-4 flex justify-center items-center">
      <section className="blog_section layout_padding">
        <div className="container">
           {/**<div className="row"> */}
            {/**<div className="col-md-6"> */}
              <div className="box bg-gray-200 dark:bg-gray-700">
                <div className="img-box  bg-white dark:bg-gray-700">
                  {/**rounded-t-lg */}
                  <img
                  className=""
                    src={coverimage}
                    alt=""
                  />
                  <h4 className="blog_date">
                  {date} <br />
                  {monthyear}
                  </h4>
                </div>
                <div className="detail-box bg-gray-200 dark:bg-gray-700 text-base text-black-500 dark:text-white">
                <p className="text-blue-400 font-semibold text-sm">🔹 {category}</p>
                  <h5>{title}</h5>
                  <p>{shortdescription}</p>
                  <a className="flex justify-center items-center" href={"/read/"+id} key={id}>Read more</a> 
                </div>
              </div>
            {/**</div> */}
          {/** </div>*/}
        </div>
      </section>
    </div>
      </>
  )
}

function NewsCard ({id, title, category, date, monthyear, coverimage, shortdescription}){
  return (
      <>
      <div className="sm:w-1/2 mb-10 px-4 flex justify-center items-center">
      <section className="blog_section layout_padding">
        <div className="container">
           {/**<div className="row"> */}
            {/**<div className="col-md-6"> */}
              <div className="box bg-gray-200 dark:bg-gray-700">
                <div className="img-box  bg-white dark:bg-gray-700">
                  {/**rounded-t-lg */}
                  <img
                  className="" 
                    src={coverimage}
                    alt=""
                  />
                  <h4 className="blog_date">
                  {date} <br />
                  {monthyear}
                  </h4>
                </div>
                <div className="detail-box bg-gray-200 dark:bg-gray-700 text-base text-black-500 dark:text-white">
                <p className="text-blue-400 font-semibold text-sm">🔹 {category}</p>
                  <h5>{title}</h5>
                  <p>{shortdescription}</p>
                  <a className="flex justify-center items-center" href={"/read/news/"+id} key={id}>Read more</a> 
                </div>
              </div>
            {/**</div> */}
          {/** </div>*/}
        </div>
      </section>
    </div>
      </>
  )
}



function HomeCards() {
  //const [searchItem, setSearchItem] = useState('')

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  useEffect(() => {
    const url = `https://goldfish-app-asx3v.ondigitalocean.app/tech/latest`;
    fetch(url)
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setItems(result);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )

  }, [])

  if (error) {
    return <div className="px-12 py-4 flex justify-center items-center dark:text-white">Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div className="px-12 py-4 flex justify-center items-center dark:text-white">Loading...</div>;
  } else {
  return (
      <>
      <section className="body-font">
          <div className="container px-5 py-12 mx-auto">
            <h1 className="flex justify-center items-center px-auto text-3xl font-bold  text-black-500 dark:text-white">LATEST</h1>
            <div className="py-2  px-2 flex flex-wrap justify-center items-center">
                <a href="/news" className="btn btn-primary btn-sm m-1">News</a>
                <a href="/computing" className="btn btn-secondary btn-sm m-1">Computing</a>
                <a href="/phones" className="btn btn-primary btn-sm m-1">Phones</a>
                <a href="/televisions" className="btn btn-secondary btn-sm m-1">Televisions</a>
                <a href="/automotive" className="btn btn-primary btn-sm m-1">Automotive</a>
                <a href="/music" className="btn btn-secondary btn-sm m-1">Music</a>
                <a href="/programming" className="btn btn-primary btn-sm m-1">Programming</a>

               
            </div>
              <div className="" style={{margin:"0 auto", padding:"10px", maxWidth:"1200px"}}>
                  <div className="px-2 py-2">
                      <div className="flex flex-wrap -mx-4 -mb-10">
                          {items.map( 
                            article => article.Category === "News" ?
                            <NewsCard 
                              key={article.Id} 
                              id={article.Id} 
                              title={article.Title} 
                              category= {article.Category} 
                              coverimage={article.CoverImageUrl} 
                              date={article.Date}
                              monthyear={article.MonthAndYear} ></NewsCard>
                          :
                            <ArticleCard 
                              key={article.Id} 
                              id={article.Id} 
                              title={article.Title} 
                              category= {article.Category} 
                              coverimage={article.CoverImageUrl} 
                              date={article.Date}
                              monthyear={article.MonthAndYear} ></ArticleCard>  
                          )}
                      </div>
                  </div>

              </div>
          </div>
      </section> 


      </>
  );}

}


function Home () {
  return(
    <>
            <TextHeader></TextHeader>
            <LazyBackgroundImageTech img={engineer}></LazyBackgroundImageTech>
        <HomeCards></HomeCards>
        <HomeFooter></HomeFooter>
    </>
  )
}
export default Home;