import { BrowserRouter , Route, Routes } from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./components/Home";
import TechNews from "./components/News";
import Computing from "./components/Computing";
import Phones from "./components/Phones";
import Televisions from "./components/Televisions";
import Music from "./components/Music";
import Automotive from "./components/Automotive";
import Programming from "./components/Programming";
import NewView from "./components/NewsView";
import ArticleView from "./components/ArticleView";
import Disclaimer from "./components/Disclaimers";
import About from "./components/About";
import Horoscope from "./components/Horoscope";
import NotFound  from "./components/NotFound";


const App = () => {
  return (
    <BrowserRouter>
      <Routes>
          <Route path = "/" element = { <Layout></Layout> }>
            <Route index element = { <Home></Home> }></Route>
            
            <Route path = "/news" element = { <TechNews></TechNews> } ></Route>
            <Route path = "/computing" element = { <Computing></Computing> } ></Route>
            <Route path = "/phones" element = { <Phones></Phones> } ></Route>
            <Route path = "/televisions" element = { <Televisions></Televisions> } ></Route>
            <Route path = "/music" element = { <Music></Music> } ></Route>
            <Route path = "/automotive" element = { <Automotive></Automotive> } ></Route>
            <Route path = "/programming" element = { <Programming></Programming> } ></Route>


            <Route path = "/read/:id"  element = { <ArticleView></ArticleView> } ></Route>
            <Route path = "/read/news/:id"  element = { <NewView></NewView> } ></Route>
            {/*<Route path = "/disclaimer" element = { <Disclaimer></Disclaimer> } ></Route>*/}
            <Route path = "/about" element = { <About></About> } ></Route>
            <Route path = "/horoscope" element = { <Horoscope></Horoscope> } ></Route>
            <Route path='*' element={<NotFound />}></Route>
          </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App